import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'

const Relatertinnhold = props => {
  const { instance } = props.pageContext
  const title = `Relevant innhold | ${instance.title}`

  return (
    <Layout {...props}>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Helmet>
      <div className="main-content content-module">
        <Container textWidth>
          <h1>Relevant innhold</h1>
          <p>
            <Link to={`/no/no/innkjopsavtaler/`}>Innkjøpsavtaler</Link>
          </p>
          <p>
            <Link to={`/no/no/innkjopsradgiver/`}>
              Innkjøpsrådgiver og innkjøpskonsulent
            </Link>
          </p>
          <p>
            <Link to={`/no/no/kostnadsanalyser/`}>Kostnadsanalyser</Link>
          </p>
          <p>
            <Link to={`/no/no/kostnadsbesparelser/`}>
              Kostnadsbesparelser og administrative kostnader
            </Link>
          </p>
        </Container>
      </div>
    </Layout>
  )
}

export default Relatertinnhold
